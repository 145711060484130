<template>
  <div
    class="card--offerings"
    :style="{
      border: `7px solid #${
        offering.membership.color_code ? offering.membership.color_code : 'FFFFFF'
      }`,
    }"
  >
    <div
      class="top--part"
      :style="{
        'background-color': `#${
          offering.membership.color_code ? offering.membership.color_code : 'FFFFFF'
        }`,
      }"
      :class="{
        'bordered--top-part':
          offering.membership.color_code === null || offering.membership.color_code === 'FFFFFF',
      }"
    >
      <div
        class="top--text"
        :class="{
          'dark--text':
            offering.membership.color_code === null || offering.membership.color_code === 'FFFFFF',
        }"
      >
        {{ offeringName }}
      </div>
      <img :src="offering.membership.logo" class="top--icon" v-if="offering.membership.logo" />
    </div>
    <div class="content--part">
      <div class="content--content">
        <div v-if="offering.discount && offering.discount > 0">
          <div class="price--before">{{ $n(offering.price, 'currency', 'id-ID') }}</div>
          <div class="price--after">
            {{ $n(offering.price - offering.discount, 'currency', 'id-ID') }}
          </div>
        </div>
        <div v-else>
          <div class="price--after">
            {{ offering.price ? $n(offering.price, 'currency', 'id-ID') : $t('general.free') }}
          </div>
        </div>
        <div class="single--point">
          <div class="point--icon">
            <v-icon>mdi-currency-usd</v-icon>
          </div>
          <div class="point--text">
            {{ `${offering.coins.amount} Koin` }}
          </div>
        </div>
        <div class="single--point">
          <div class="point--icon">
            <v-icon>mdi-calendar</v-icon>
          </div>
          <div class="point--text">
            {{ `${offering.membership.duration} Hari` }}
          </div>
        </div>
        <div class="single--point">
          <div class="point--icon">
            <v-icon>mdi-check</v-icon>
          </div>
          <div class="point--text">
            {{ $t('offering.information') }}
          </div>
        </div>
        <div class="single--point" v-if="offering.membership.type === 'EXECUTIVE'">
          <div class="point--icon">
            <v-icon>mdi-account-details-outline</v-icon>
          </div>
          <div class="point--text">{{ $t('offering.hotLeads') }}*</div>
        </div>
        <div class="listing--amount">
          {{ offering.available_listing.amount }} {{ $t('offering.listingAmount') }}
        </div>
      </div>
      <div class="bottom--part">
        <img :src="offering.membership.logo" class="bottom--icon" v-if="offering.membership.logo" />
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  components: {},
  props: {
    offering: {
      require: true,
    },
  },
  computed: {
    offeringName() {
      if (this.offering && this.offering.name) {
        return this.offering.name.replace('Membership ', '').toUpperCase();
      }
      return '';
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.card--offerings {
  position: relative;
  border-radius: 20px;
  box-shadow: $box-shadow-card;
}
.top--part {
  position: absolute;
  top: -25px;
  left: 40px;
  width: calc(100% - 80px);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;

  @media #{$tablets} {
    top: -20px;
    left: 30px;
    width: calc(100% - 60px);
    height: 40px;
  }

  @media #{$phones} {
    top: -20px;
    height: 40px;
  }

  .top--text {
    font-family: Poppins-SemiBold;
    font-size: $font-xl;
    color: $color-white;

    @media #{$phones, $tablets} {
      font-size: $font-ml;
    }
  }

  .dark--text {
    color: $color-text-gray-3;
  }

  .top--icon {
    height: 36px;
    margin-left: 8px;
  }
}
.bordered--top-part {
  border: solid 3px $color-text-gray-3;
}
.offering--silver {
  border: 7px solid #858686;
  .top--part {
    background: linear-gradient(93deg, #858686 4.08%, #b9b9b9 51.04%, #585d5d 98.01%);
  }
}
.offering--gold {
  border: 7px solid #f4c149;
  .top--part {
    background: linear-gradient(93deg, #f4bc39 4.08%, #ffd67f 62.69%, #ffe4a5 98.01%);
  }
}
.offering--platinum {
  border: 7px solid #3b3a38;
  .top--part {
    background: linear-gradient(93deg, #3b3a38 4.08%, #aeaeae 51.04%, #636363 98.01%);
  }
}
.content--part {
  position: relative;
  padding: 60px 40px 50px 40px;
  overflow: hidden;
  @media #{$phones, $tablets} {
    padding: 40px 20px 30px 20px;
  }
  .content--content {
    position: relative;
    z-index: 10;
  }
  .price--before {
    font-family: Poppins-SemiBold;
    font-size: $font-xxl;
    color: $color-beliruma-1-light;
    text-align: center;
    text-decoration: line-through;

    @media #{$phones, $tablets} {
      font-size: $font-xl;
    }
  }
  .price--after {
    font-family: Poppins-Bold;
    font-size: $font-dl;
    color: $color-beliruma-1;
    text-align: center;
    margin-bottom: 36px;

    @media #{$phones, $tablets} {
      font-size: $font-xxxl;
      margin-bottom: 24px;
    }
  }
  .single--point {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .point--icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $color-text-gray-3;
      margin-right: 8px;
      .v-icon {
        color: $color-white;
        font-size: 16px;
      }
    }
    .point--text {
      font-family: Poppins-SemiBold;
      font-size: $font-sm;
      color: $color-text-gray-3;
    }
  }
  .listing--amount {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 12px;
    background-color: $color-text-gray-3;
    font-family: Poppins-Bold;
    font-size: $font-sm;
    color: $color-white;
    margin-top: 50px;

    @media #{$phones, $tablets} {
      margin-top: 30px;
    }
  }
}
.bottom--part {
  position: absolute;
  bottom: -40px;
  right: -20px;
  .bottom--icon {
    height: 140px;
    opacity: 25%;
  }
}
</style>
